import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
//import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'

class AkoStavat extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba portálu Akostavat'}
          description={'Prípadová štúdia'}
          description2={"Profesionálna webstránka so všetkým pre stavebníctvo na jednom mieste. Niečo čo na Slovensku dlho chýbalo."}
          text={"Pre klienta sme vytvorili všetko od návrhu UX, wireframy až po webový dizajn portálu. Web návštevníkom prináša hodnotný obsah a novinky zo sveta stavebníctva a združuje stavebníkov."}
          img={data.landingImage}
          what_we_done={[
            { text: 'UX analýza' },
            { text: 'Tvorba wireframov' },
            { text: 'Webový design' }
          ]} />

        <AboutProject
          title={'Profesionálny web nevznikne sám od seba'}
          text={'Vytvorenie web stránky na najvyššej úrovni si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Našou úlohou bolo navrhnúť UX a moderný webový dizajn, ktorý pomôže tomuto <a class="blue-link" href="/tvorba-webov">portálu</a> budovať dôveru a nasmeruje návštevníkov k požadovanej akcii. To bol kľúčový cieľ, na ktorý sme sa zamerali. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť minimalistický a prehľadný <a class="blue-link" href="/webdizajn">dizajn web stránky</a>, v ktorom sa návštevník rýchlo zorientuje a okamžite nájde to, čo práve potrebuje. Cieľom bol <b>skvelý používateľský zážitok</b>, aby sme znížili bounce rate (mieru odchodov) a zvýšili konverzie. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Pri projektoch sa často navrhuje aj <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Výsledkom bol perfektne odladený moderný UX webový dizajn.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálny portál sa bez neho nezaobíde.'}
          text={'Prepracované UX, high-fidelity wireframy a webový dizajn na mieru pre portál Ako stavať obsahujú všetko, čo by mal moderný a nadčasový projekt obsahovať, keď chce, aby sa do neho návštevníci opakovane vracali. Perfektná responzívnosť a mobile first prístup sú už len čerešničkou na torte.'}
          projectDetail={'/nase-prace/webstranky/portaly/akostavatcom'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'HP alternatívny návrh',
              image: data.pageTwo,
            },
            {
              name: 'Detail článku',
              image: data.pageThree,
            },
            {
              name: 'Výrobcovia',
              image: data.pageFour,
            },
            {
              name: 'Poradňa',
              image: data.pageSix,
            },
            {
              name: 'Odborné odpovede',
              image: data.pageSeven,
            },
            {
              name: 'Kalkulačka',
              image: data.pageEight,
            },
            {
              name: 'Kalendár',
              image: data.pageNine,
            }
          ]} />


        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="akostavat" />
          </div>
        </Reveal>


        <div className={'dark-section'}>
          <Reveal>
            <div className={'container wrapper'}>
              <div className={'row'}>
                <div className={'col-md-7'}>
                  <h2 className={'cs-title'}>Mobilná verzia</h2>
                  <p className="description">Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.</p>
                  <p className="description-smaller">Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br /><br />Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný portál a chce, aby mu prinášal čo najväčší zisk.</p>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-4 img-col-1'}>
                  <Img alt={this.constructor.name} fluid={data.screenOne.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenTwo.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenThree.childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 img-col-2'}>
                  <Img alt={this.constructor.name} fluid={data.screenFour.childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 img-col-3'}>
                  <Img alt={this.constructor.name} fluid={data.screenFive.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </Reveal>
        </div>


        {/*  <MobileCarousel
          items={[
            data.screenTwo,
            data.screenThree,
            data.screenFour,
            data.screenFive,
          ]}
        /> */}

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.akostavat.com" target='_blank' className='link-secondary'>www.akostavat.com</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Cliqdate'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/portaly/cliqdate'}
        /* nextProjectName={'Bharter'}
        nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/portaly/bharter'} */
        />
      </Layout>
    )
  }
}

export const query = graphql`{
    project: markdownRemark(fields: {slug: {regex: "/akostavat/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Ako_stavat_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/akostavat_v4.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/akostavat_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/akostavat_vsetko_o_stavbe_legislativa_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/akostavat_vyrobcovia_a_produkty_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/akostavat_produkt_detail_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/akostavat_poradna_odoslanie_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSeven: file(relativePath: { eq: "case-studies/akostavat_poradna_odborne_odpovede_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEight: file(relativePath: { eq: "case-studies/akostavat_kalkulacka_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageNine: file(relativePath: { eq: "case-studies/akostavat_kalendar_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTen: file(relativePath: { eq: "case-studies/akostavat_checklist_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEleven: file(relativePath: { eq: "case-studies/akostavat_detail_sutaze_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwelve: file(relativePath: { eq: "case-studies/akostavat_detail_clanku_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThirteen: file(relativePath: { eq: "case-studies/akostavat_kontakt_v1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/SangreAzul_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/sangreAzul_CorporateIdentity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenOne: file(relativePath: { eq: "case-studies/Ako_stavat_screenOne.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTwo: file(relativePath: { eq: "case-studies/Ako_stavat_screenTwo.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenThree: file(relativePath: { eq: "case-studies/Ako_stavat_screenThree.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFour: file(relativePath: { eq: "case-studies/Ako_stavat_screenFour.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFive: file(relativePath: { eq: "case-studies/Ako_stavat_screenFive.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default AkoStavat
